import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {authInterface, LoginDataInterface} from "./type";
import {prefix} from "./actions";

// 초기 state
const initialState: authInterface = {
    isWorking: false,
    isChecking: false
};

// slice
const auth = createSlice({
    name: prefix,
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<LoginDataInterface>) => {
            const {
                user_key,
                user_name,
                authority_key,
                Authorization,
                user_profile_url,
                isManager
            } = action.payload
            // console.log(action.payload);
            state.user = {
                user_key: user_key,
                user_name: user_name,
                user_profile_url: user_profile_url,
                token: Authorization,
                authority_key: authority_key,
                isManager: isManager ? isManager : false
            };
        },
        updateProfile: (state, action: PayloadAction<string>) => {
            if (state.user) {
                state.user = {...state.user, user_profile_url: action.payload}
            }
        },
        checkingSet: (state, action: PayloadAction<boolean>) => {
            state.isChecking = action.payload
        },
        workingSet: (state, action: PayloadAction<{ working: boolean, message?: string }>) => {
            state.isWorking = action.payload.working
            state.message = action.payload.message
        },
        logoutUser: () => initialState
    }
})

export const {updateProfile, setUser, logoutUser, workingSet, checkingSet} = auth.actions;
export const authReducer = auth.reducer;