import i18next from "i18next";

export interface MenuItemInterface {
    id?: string
    title2?: string
    title?: any
    path?: string
    parent?: string
    click_id?: string
    depth?: number
    children?: {
        id: string;
        title: string
        title2?: string
        path: string
        parent: string
        depth: number
        click_id?: string
        children?: {
            id: string;
            title: string
            title2?: string
            path: string
            parent: string
            depth: number
        }[]
    }[]
}




/*[{path: string, depth2: [{path: string, depth3: [{path: string, title: string}, {path: string, title: string}, {path: string, title: string}], title: string}, {path: string, title: string}, {path: string, title: string}, {path: string, title: string}], title: string}, {path: string, depth2: [{path: string, depth3: [{path: string, title: string}, {path: string, title: string}], title: string}, {path: string, depth3: [{path: string, title: string}, {path: string, title: string}, {path: string, title: string}], title: string}, {path: string, depth3: [{path: string, title: string}, {path: string, title: string}], title: string}, {path: string, depth3: [{path: string, title: string}, {path: string, title: string}, {path: string, title: string}], title: string}, {path: string, depth3: [{path: string, text: string}], title: string}], title: string}, {path: string, depth2: [{path: string, depth3: [{path: string, title: string}, {path: string, title: string}, {path: string, title: string}, {path: string, title: string}, {path: string, title: string}], title: string}, {path: string, depth3: [{path: string, title: string}, {path: string, title: string}], title: string}], title: string}, {depth2: [{title: string}, {title: string}], title: string}, {depth2: [{title: string}, {title: string}], title: string}]*/
const menuItems: MenuItemInterface[] = [
    {
        id: 'introduce', title:"menu1.d01", title2:"menu1.d01", path: "/company/introduce", depth: 1, click_id: 'introduce_3',
        children: [
            {
                id: 'introduce_2',
                title:"menu1.d0101", title2: "menu1.d0101",
                path: "/company/introduce",
                parent: "introduce",
                depth: 2,
                click_id: 'introduce_3',
                children:
                    [
                        {
                            id: 'introduce_3',
                            title:"menu1.d010101", title2: "menu1.d010101",
                            path: "/company/introduce",
                            parent: "introduce_2",
                            depth: 3,
                        },
                        {id: 'mission_3',title:"menu1.d010102", title2: "menu1.d010102", path: "/company/mission", parent: "introduce_2", depth: 3,},
                        {id: 'history_3',title:"menu1.d010103", title2: "menu1.d010103",  path: "/company/history", parent: "introduce_2", depth: 3,}
                    ]
            },
            {id: 'ceo_2', title:"menu1.d0102", title2: "menu1.d0102",  path: "/company/ceo", parent: "introduce", depth: 2,},
            {id: 'partner_2',title:"menu1.d0103", title2: "menu1.d0103",  path: "/company/partner", parent: "introduce", depth: 2,},
            {id: 'contact_2', title:"menu1.d0104", title2: "menu1.d0104",  path: "/company/contact", parent: "introduce", depth: 2,},
        ]
    },
    {
        id: 'onpremise', title:"menu2.d01", title2: 'menu2.d01', path: "/solution/onpremise", depth: 1, click_id: 'onpremise_3',
        children: [
            {
                id: 'onpremise_2',
                title:"menu2.d0101", title2: 'menu2.d0101',
                path: "/solution/onpremise",
                parent: "onpremise",
                depth: 2,
                click_id: 'onpremise_3',
                children:
                    [
                        {
                            id: 'onpremise_3',
                            title:"menu2.d010101", title2: 'menu2.d010101',
                            path: "/solution/onpremise",
                            parent: "onpremise_2",
                            depth: 3
                        },
                        {
                            id: 'cloudPACS_3',
                            title:"menu2.d010102", title2: 'menu2.d010102',
                            path: "/solution/cloudPACS",
                            parent: "onpremise_2",
                            depth: 3
                        }
                    ]
            },
            {
                id: 'visionmakerDR_2',
                title:"menu2.d0102", title2: 'menu2.d0102',
                path: "/solution/visionmakerDR",
                parent: "onpremise",
                depth: 2,
                click_id: 'visionmakerDR_3',
                children:
                    [
                        {
                            id: 'visionmakerDR_3',
                            title:"menu2.d010201", title2: 'menu2.d010201',
                            path: "/solution/visionmakerDR",
                            parent: "visionmakerDR_2", depth: 3
                        },
                        {
                            id: 'visionmakerENDO_3',
                            title:"menu2.d010202", title2: 'menu2.d010202',
                            path: "/solution/visionmakerENDO",
                            parent: "visionmakerDR_2", depth: 3
                        },
                        {
                            id: 'visionmakerFUNDUS_3',
                            title:"menu2.d010203", title2: 'menu2.d010203',
                            path: "/solution/visionmakerFUNDUS",
                            parent: "visionmakerDR_2", depth: 3
                        }
                    ]
            },
            {
                id: 'aiWomanCare_2',
                title:"menu2.d0103", title2: 'menu2.d0103',
                path: "/solution/aiWomanCare",
                parent: "onpremise",
                depth: 2,
                click_id: 'aiWomanCare_3',
                children:
                    [
                        {
                            id: 'aiWomanCare_3',
                            title:"menu2.d010301", title2: 'menu2.d010301',
                            path: "/solution/aiWomanCare",
                            parent: "aiWomanCare_2",
                            depth: 3
                        },
                        {
                            id: 'aiEchoCare_3',
                            title:"menu2.d010302", title2: 'menu2.d010302',
                            path: "/solution/aiEchoCare",
                            parent: "aiWomanCare_2",
                            depth: 3
                        }
                    ]
            },
            {
                id: 'tRouter_2',
                title:"menu2.d0104", title2: 'menu2.d0104',
                path: "/solution/tRouter",
                parent: "onpremise",
                depth: 2,
                click_id: 'tRouter_3',
                children:
                    [
                        {id: 'tRouter_3',  title:"menu2.d010401", title2: 'menu2.d010401', path: "/solution/tRouter", parent: "tRouter_2", depth: 3},
                        {id: 'tGear_3', title:"menu2.d010402", title2: 'menu2.d010402', path: "/solution/tGear", parent: "tRouter_2", depth: 3},
                        {id: 'tClient_3', title:"menu2.d010403", title2: 'menu2.d010403', path: "/solution/tClient", parent: "tRouter_2", depth: 3}
                    ]
            },
            {
                id: 'autovoice_2',
                title:"menu2.d0105", title2: 'menu2.d0105',
                path: "/solution/autovoice",
                parent: "onpremise",
                depth: 2,
                click_id: 'autovoice_3',
                children:
                    [
                        // {text: "Dr.Essen", path: "/DrEssen"},
                        {
                            id: 'autovoice_3',
                            title:"menu2.d010501", title2: 'menu2.d010501',
                            path: "/solution/autovoice",
                            parent: "autovoice_2",
                            depth: 3
                        }
                    ]
            }
        ]
    },
    {
        id: 'facial', title:"menu3.d01", title2: 'menu3.d01', path: "/tech/facial", depth: 1, click_id: 'facial_3',
        children: [
            {
                id: 'facial_2',
                title:"menu3.d0101", title2: 'menu3.d0101',
                path: "/tech/facial",
                parent: "facial",
                depth: 2,
                click_id: 'facial_3',
                children:
                    [

                        {id: 'facial_3', title:"menu3.d010101", title2: 'menu3.d010101', path: "/tech/facial", parent: "facial_2", depth: 3},
                        {id: 'body_3', title:"menu3.d010102", title2: 'menu3.d010102',  path: "/tech/body", parent: "facial_2", depth: 3},
                        {
                            id: 'de-indentification_3',
                            title:"menu3.d010103", title2: 'menu3.d010103',
                            path: "/tech/de-indentification",
                            parent: "facial_2",
                            depth: 3
                        },
                        {id: 'gene_3', title:"menu3.d010104", title2: 'menu3.d010104',  path: "/tech/gene", parent: "facial_2", depth: 3},
                    ]
            },
            {
                id: 'annotation_2',
                title:"menu3.d0102", title2: 'menu3.d0102',
                path: "/tech/blockchain",
                parent: "facial",
                depth: 2,
                click_id: 'blockchain_3',
                children:
                    [
                        // {
                        //     id: 'annotation_3',
                        //     title: "어노테이션",
                        //     path: "/tech/annotation",
                        //     parent: "annotation_2",
                        //     depth: 3
                        // },
                        {
                            id: 'blockchain_3',
                            title:"menu3.d010102", title2: 'menu3.d010102',
                            path: "/tech/blockchain",
                            parent: "annotation_2",
                            depth: 3
                        },
                        // {
                        //     id: 'datatransfer_3',
                        //     title: "데이터전송기술",
                        //     path: "/tech/datatransfer",
                        //     parent: "blockchain_2",
                        //     depth: 3
                        // }
                    ]
            }
        ]
    },
    {
        id: 'post', title:"menu4.d01", title2: 'menu4.d01',  path: '/post/notice', depth: 1, click_id: 'notice_2',
        children: [
            {id: 'notice_2',  title:"menu4.d0101", title2: 'menu4.d0101', path: '/post/notice', parent: "post", depth: 2},
            {id: 'news_2',  title:"menu4.d0102", title2: 'menu4.d0102', path: '/post/news', parent: "post", depth: 2}
        ]
    },
    {
        id: 'contactus',  title:"menu5.d01", title2: 'menu5.d01', path: '/contactus/remote', depth: 1, click_id: 'remote_2',
        // id: 'contactus', title: "고객센터", path: '/contactus/remote', depth: 1, click_id: 'online_2',
        children: [
            // {id: 'online_2', title: "온라인문의", path: '/contactus/qna', parent: "contactus", depth: 2},
            {id: 'remote_2', title: "원격지원", path: '/contactus/remote', parent: "contactus", depth: 2}
        ]
    }
];
export default menuItems;