import React, {useEffect, useState, MouseEvent, useRef, useLayoutEffect} from "react";
import '../assets/css/contents.css';
//import OnPremise from "../pages/solution/onpremise";
import {Link, NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import Depth2menu from "./depth2menu";
import {ReactComponent as IconHome} from '../assets/img/sub/icon_home.svg'
import "../layout/menuData"

import svideo01 from "../assets/img/sub/svisual_01.mp4";
import svideo02 from "../assets/img/sub/svisual_02.mp4";
import svideo03 from "../assets/img/sub/svisual_03.mp4";
import svideo04 from "../assets/img/sub/svisual_04.mp4";
import svideo05 from "../assets/img/sub/svisual_05.mp4";
import Gnb_1depth from "./gnb_1depth";
import Gnb_2depth from "./gnb_2depth";
import MenuData, {MenuItemInterface} from "./menuData";
import FootModal from "../component/foot_modal";
import LoginModal from "../component/login";
import Privacy from "../component/privacy";
import Terms from "../component/terms";
import Email from "../component/email";
import menuItems from "./menuData";
import {useSelector} from "react-redux";
import {RootState} from "../store/type";
import {activeMenuInterface} from "../store/config/type";
import {menuSet as menuSetSagaStart} from '../store/config';
import { getActiveMenu } from "./Layout";
import { useDispatch } from "react-redux";
import MainModal from "../pages/main/MainModal";
import Service from "../component/service";
import useWindowSize from "../hook/useWindowSize";
import {isDesktop} from "react-device-detect";
import {Trans, useTranslation} from "react-i18next";

function VideoDepth({depthId}: { depthId: string }) {
    return (
        <>
            {depthId === 'introduce' && (
                <video muted autoPlay={isDesktop} loop>
                    <source src={svideo01} type="video/mp4"/>
                </video>
            )}
            {depthId === 'onpremise' && (
                <video muted autoPlay={isDesktop} loop>
                    <source src={svideo02} type="video/mp4"/>
                </video>
            )}
            {depthId === 'facial' && (
                <video muted autoPlay={isDesktop} loop>
                    <source src={svideo03} type="video/mp4"/>
                </video>
            )}
            {depthId === 'post' && (
                <video muted autoPlay={isDesktop} loop>
                    <source src={svideo04} type="video/mp4"/>
                </video>
            )}
            {depthId === 'contactus' && (
                <video muted autoPlay={isDesktop} loop>
                    <source src={svideo05} type="video/mp4"/>
                </video>
            )}
        </>
    )
}

function SubLayout() {
    const menuConfig = useSelector<RootState, activeMenuInterface>(state => state.config.menu)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const size = useWindowSize();

    const [isModalOpen, setIsModalOpen] = useState<string | null>(null);
    const openPrivacy = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsModalOpen('privacy');
    }
    const openService = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsModalOpen('service');
    }
    const openTerms = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsModalOpen('terms')
    }
    const openEmail = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsModalOpen('email');
    }
    const closeModal = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsModalOpen(null);
        document.body.style.position = "relative";
    };

    const [gnb1Depth, setgnb1Depth] = useState(false);
    const [gnb2Depth, setgnb2Depth] = useState(false);
    const [depth1, setDepth1] = useState<MenuItemInterface>({});
    const [depth2, setDepth2] = useState<MenuItemInterface>({});
    const [depth3, setDepth3] = useState<MenuItemInterface>({});

    useEffect(() => {
        if (menuConfig.depth1) setDepth1(menuConfig.depth1)
        if (menuConfig.depth2) setDepth2(menuConfig.depth2)
        if (menuConfig.depth3) setDepth3(menuConfig.depth3)
    }, [menuConfig]);
    const onHandleDepth = (e: MouseEvent<HTMLAnchorElement>) => {
        // const activeMenu = getActiveMenu(search_id, null)
        // dispatch(menuSetSagaStart(activeMenu))
        const anchor_list = document.querySelectorAll('.depth3_tab > a')
        for (let i = 0, item; item = anchor_list[i]; i++) {
            item.classList.remove('active');
        }
        e.currentTarget.classList.add('active');
    }
    const onSelectMenu = (selectItem:any) =>{
        const {click_id, id, path} = selectItem;
        const search_id = click_id ? click_id : id;
        const activeMenu = getActiveMenu(search_id, null)
        dispatch(menuSetSagaStart(activeMenu))
        // navigate(path);
    }

    const { pathname } = useLocation();

    useEffect(()=>{
        if(window.location.pathname  !== '/'){
            window.scrollTo({top: 950, left: 0});
            if(size.width && size.width < 1500) window.scrollTo({top: 0, left: 0});
        }else{
            window.scrollTo({top: 0, left: 0});
        }
    },[pathname])

    const {t} = useTranslation('sublayout');
    const {t:menuT} = useTranslation('menu');

    let title1 = depth1?.title || ''
    if(depth1['title2']){
        title1 = menuT(depth1['title2'])
    }
    let title2 = depth2?.title || ''
    if(depth2['title2']){
        title2 = menuT(depth2['title2'])
    }
    let title3 = depth3?.title || ''
    if(depth3['title2']){
        title3 = menuT(depth3['title2'])
    }
    return (
        <div>
            <div className="s_content_wrap">
                <div id="s_visual" className="s_visual svisual02">
                    <VideoDepth depthId={depth1.id || ''}/>
                    <h3>{title1}</h3>
                    {(depth2.title && depth3.title === undefined) && <h4>{title2}</h4>}
                    {depth3.title && <h4>{title3}</h4>}
                    <p>Differentiated Service Providing an Effective Solution</p>
                    {/* location */}
                    <div className="location">
                        <Link to="/"><i><IconHome/></i></Link>
                        <div className="txt box1">
                            <span onClick={() => setgnb1Depth(!gnb1Depth)}>{title1}</span>
                            {gnb1Depth && <Gnb_1depth/>}
                        </div>
                        <div className=" txt box2">
                            <span className=" stit1" onClick={() => setgnb2Depth(!gnb2Depth)}>{title2}</span>
                            {gnb2Depth === true && <Gnb_2depth/>}
                        </div>
                    </div>
                    {/* //location */}
                    <p className="scroll">SCROLL <span></span></p>
                </div>
            </div>
            <section>
                <div id="target_content_box" className="content_box" >
                    <div className="sub_conttit lnb ">
                        <h3 className="depth1_menu">{title1}</h3>
                        <h3 className="depth2_menu">{title2}</h3>
                        {depth2.children && (
                            <ul>
                                {depth2.children.map((item) => {
                                    let title = item?.title || ''
                                    if(item['title2']){
                                        title = menuT(item['title2'])
                                    }
                                    return (
                                        <li key={item.id} className="depth3_tab" onClick={()=>onSelectMenu(item)}>
                                            <Link to={item.path}
                                                  onClick={onHandleDepth}
                                                  className={item.id === depth3.id ? 'active' : ''}>
                                                {title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                    </div>
                    <div className="content">
                        <Outlet/>
                    </div>

                </div>
                <div className="s_footer">
                    <div className="footer_l">
                        <dl>
                            <dt>{t('address.title')}</dt>
                            <dd> <Trans components={{ br: <br/>}}>{t('address.txt')}</Trans>
                            </dd>
                        </dl>
                        <dl>
                            <dt>Tel.</dt>
                            <dd><a href="tel:1644-3751">1644-3751</a></dd>
                        </dl>
                        <dl>
                            <dt>E-mail</dt>
                            <dd>help@inviz.co.kr</dd>
                        </dl>
                    </div>
                    <div className="footer_r">
                        <ul>
                            <li>
                                <a onClick={openPrivacy} className="privacy">{t('privacy')}</a>
                                {/*<a onClick={openTerms}>이용약관</a>*/}
                                {/* <a onClick={openService} className="pr0">서비스 이용약관</a> */}
                                <a onClick={openEmail} className="pr0">{t('email')}</a>
                            </li>
                            <li>{t('copy')}</li>
                        </ul>
                    </div>
                    {/*<FootModal isOpen={isModalOpen} closeModal={closeModal} >*/}
                    {/*    aaa*/}
                    {/*</FootModal>*/}
                    {isModalOpen === 'privacy' && <Privacy closeModal={closeModal}/>}
                    {isModalOpen === 'service' && <Service closeModal={closeModal}/>}
                    {isModalOpen === 'terms' && <Terms closeModal={closeModal}/>}
                    {isModalOpen === 'email' && <Email closeModal={closeModal}/>}
                    {/* {isModalOpen === 'privacy' && <MainModal type={'privacy'} closeModal={(e:any)=>{closeModal(e)}}/>} */}
                    {/* {isModalOpen === 'email' && <MainModal type={'email'} closeModal={(e:any)=>{closeModal(e)}}/>} */}
                    
                </div>
            </section>

        </div>
    )
}

export default SubLayout;