import React, {lazy, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import menuItems, {MenuItemInterface} from "./menuData";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const Header = lazy(() => import("./Header"));
export default function Layout() {
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState(i18n.language)
    useEffect(() => {
        console.log(language)
    }, [language]);
    useEffect(() => {
        const handleLanguageChange = () => {
            console.log('Language changed to:', i18n.language);
            setLanguage(i18n.language)
        };

        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);


    return (
        <div className={classNames('inviz2023', language)}>
            <Header getActiveMenu={getActiveMenu}/>
            <Outlet/>
        </div>
    )
}


const getNode = (_children: MenuItemInterface[], _result: any, _id: string | null = null, _path: string | null = null) => {
    for (let i = 0, child; child = _children[i]; i++) {
        if (_id && child.id === _id) {
            return child;
        } else if (_path && child.path === _path) {
            if (child.children) {
                _result = getNode(child.children, _result, _id, _path);
            } else {
                return child
            }
        } else {
            if (child.children) {
                _result = getNode(child.children, _result, _id, _path);
            }
        }
    }
    return _result
}

export function getActiveMenu(id: string | null = null, path: string | null = null) {
    const result: MenuItemInterface = getNode(menuItems, {}, id, path)
    let depth1: MenuItemInterface = {}
    let depth2: MenuItemInterface = {}
    let depth3: MenuItemInterface = {}
    if (result.depth === 1) {
        depth1 = result;
    } else if (result.depth === 2) {
        depth1 = getNode(menuItems, {}, result.parent)
        depth2 = result;
    } else if (result.depth === 3) {
        depth2 = getNode(menuItems, {}, result.parent)
        if (depth2) {
            depth1 = getNode(menuItems, {}, depth2.parent)
        }
        depth3 = result
    }
    const activeMenu = {
        depth1: depth1,
        depth2: depth2,
        depth3: depth3,
    }
    return activeMenu;
}
