import {takeLatest, put} from "redux-saga/effects";
import {configSet, menuSet} from "./actions";
import {setConfig, setMenu} from "./slice";
import {PayloadAction} from "@reduxjs/toolkit";
import {activeMenuInterface, configJsonInterface} from "./type";

function* configSetSaga(action: PayloadAction<configJsonInterface>) {
    try {
        sessionStorage.setItem('config', JSON.stringify(action.payload))
        yield put(setConfig(action.payload))
    } catch (error) {
    }
}

function* MenuSetSaga(action: PayloadAction<activeMenuInterface>) {
    try {
        yield put(setMenu(action.payload))
    } catch (error) {
    }
}

export function* configSaga() {
    yield takeLatest(configSet, configSetSaga);
    yield takeLatest(menuSet, MenuSetSaga);
}