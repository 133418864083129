import React, {lazy, Suspense, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import '../src/assets/css/default.css';
import '../src/assets/css/en.css';
import './App.css';
import './layout/menuData'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./layout/Layout";
import SubLayout from "./layout/sublayout";
import Contactus from "./pages/board/contactus";
import axios from "axios";
import {useQuery} from "react-query";
import {useDispatch} from "react-redux";
import {configSet as configSetSagaStart} from './store/config';
import Loading from "./component/common/Loading";
import NonePage from './pages/error/NonePage';
import "./locales/i18n"
import {useTranslation} from "react-i18next";

//import Remote from "./pages/cs/remote";

function App() {
    const dispatch = useDispatch();
    const getUserConfig = async () => {
        const data = await axios
            .get(process.env.PUBLIC_URL + '/config/' + process.env.REACT_APP_CONFIG)
            .then((response) => response.data);
        return data;
    };
    const {isLoading} = useQuery('userConfig', getUserConfig, {
        refetchOnWindowFocus: false,
        suspense: false,
        onSuccess: data => {
            dispatch(configSetSagaStart(data))
        },
    });
    const Cs = lazy(() => import("./pages/cs/Cs"));
    const Main = lazy(() => import("./pages/main/Main"));
    const Company = lazy(() => import("./pages/company/Company"));
    const Solution = lazy(() => import("./pages/solution/Solution"));
    const Tech = lazy(() => import("./pages/tech/Tech"));
    const PostList = lazy(() => import("./pages/post/PostList"));
    const PostView = lazy(() => import("./pages/post/PostView"));
    const PostWrite = lazy(() => import("./pages/post/PostWrite"));
    const Notice = lazy(() => import("./pages/community/NoticePage"));
    const News = lazy(() => import("./pages/community/NewsPage"));


    if (isLoading) return <Loading/>;
    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path="/" element={<Main/>}/>
                    <Route element={<SubLayout/>}>
                        <Route path="/company/:menuPath" element={<Company/>}/>
                        <Route path="/solution/:menuPath" element={<Solution/>}/>
                        <Route path="/tech/:menuPath" element={<Tech/>}/>
                        <Route path="/contactus/:menuPath" element={<Cs/>}/>
                        <Route path="/post/*">
                            <Route path='notice/*' element={<Notice/>}/>
                            <Route path='news/*' element={<News/>}/>
                        </Route>
                    </Route>
                    <Route path="*" element={<NonePage/>}/>
                </Route>
            </Routes>
            <ToastContainer/>
        </Suspense>
    );
}

export default App;
