import {EncryptStorage} from 'encrypt-storage';

const SECRET_KEY: string = process.env.REACT_APP_SECRET_KEY
    ? process.env.REACT_APP_SECRET_KEY
    : 'j230498239urjfsadfiko293u-94ujsdfj902u43-(&U()&JKi';
export const encryptStorage = new EncryptStorage(SECRET_KEY, {
    prefix: '@InvizInstance',
    storageType: 'sessionStorage',
});

export const encryptLocalStorage = new EncryptStorage(SECRET_KEY, {
    prefix: '@InvizInstance',
    storageType: 'localStorage',
});