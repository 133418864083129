import React, {useEffect, useRef, useState} from "react";
import Foot_modal from "./foot_modal";
import {Trans,useTranslation} from "react-i18next";


function Privacy(closeModal:any) {
    const {t} = useTranslation('privacy');

    return (
        <Foot_modal closeModal={closeModal}>
            <h2>{t('privacy.title1')}</h2>
            <Trans components={{h3: <h3/>}}>{t('privacy.h3_tit1')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit1')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p1')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit2')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p2')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit3')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p3')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit4')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p4')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit5')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p5')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit6')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p6')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit7')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p7')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit8')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p8')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit9')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p9')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit10')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p10')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit11')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p11')}</Trans>
            <Trans components={{h4: <h4/>}}>{t('privacy.h4_tit12')}</Trans>
            <Trans components={{p: <p/>,br: <br/>}}>{t('privacy.p12')}</Trans>
        </Foot_modal>
    )
}

export default Privacy;