import React, { useEffect, useState } from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import menuData, { MenuItemInterface } from "./menuData";
import { useDispatch, useSelector } from "react-redux";
import {menuSet as menuSetSagaStart} from '../store/config';
import { activeMenuInterface } from "../store/config/type";
import { RootState } from "../store/type";
import { getActiveMenu } from "./Layout";

interface depth2Interface {
    id: string;
    title: string
    path: string
    parent: string
    depth: number
    click_id?: string
    children?:{
        id: string;
        title: string
        path: string
        parent: string
        depth: number
    }
}
function Gnb_2depth() {
    const menuConfig = useSelector<RootState, activeMenuInterface>(state => state.config.menu)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [depth2Menu, setDepth2Menu] = useState<any>(menuData[0].children)
    const onSelectMenu = (selectItem:any) =>{
        const {click_id, id, path} = selectItem;
        const search_id = click_id ? click_id : id;
        const activeMenu = getActiveMenu(search_id, null)
        dispatch(menuSetSagaStart(activeMenu))
        navigate(path);
    }
    useEffect(()=>{
        setDepth2Menu(menuConfig.depth1?.children)
    },[menuConfig])
    return (
        <div className=" submenu_box subm2">
            <ul className=" subDepth sub_menu menu1">
                {depth2Menu.map((item:any, index:number) => (
                    <li key={item.click_id ? item.click_id : item.id ? item.id : index} onClick={()=>onSelectMenu(item)}>
                        {/* <Link to={item.path || ''}> */}
                            {item.title}
                        {/* </Link> */}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Gnb_2depth;