import i18n from 'i18next'
import {initReactI18next} from 'react-i18next';
import mainKO from "./ko/main.json"
import menuKO from "./ko/menu.json"
import sublayoutKO from "./ko/sublayout.json"
import introduceKO from "./ko/introduce.json"
import historyKO from "./ko/history.json"
import missionKO from "./ko/mission.json"
import ceoKO from "./ko/ceo.json"
import partnerKO from "./ko/partner.json"
import contactKO from "./ko/contact.json"
import onpremiseKO from "./ko/onpremise.json"
import cloudKO from "./ko/cloud.json"
import vmdrKO from "./ko/vmdr.json"
import vmendoKO from "./ko/vmendo.json"
import vmfundusKO from "./ko/vmfundus.json"
import aiwomanKO from "./ko/aiwoman.json"
import aiechoKO from "./ko/aiecho.json"
import trouterKO from "./ko/trouter.json"
import tgearKO from "./ko/tgear.json"
import tclientKO from "./ko/tclient.json"
import autovoiceKO from "./ko/autovoice.json"
import facialKO from "./ko/facial.json"
import bodyKO from "./ko/body.json"
import deindentiKO from "./ko/deindenti.json"
import geneKO from "./ko/gene.json"
import blockchainKO from "./ko/blockchain.json"
import remoteKO from "./ko/remote.json"
import privacyKO from "./ko/privacy.json"
import emailKO from "./ko/email.json"
import boardlistKO from "./ko/boardlist.json"
import photolistKO from "./ko/photolist.json"

import mainEN from "./en/main.json"
import menuEN from "./en/menu.json"
import sublayoutEN from "./en/sublayout.json"
import introduceEN from "./en/introduce.json"
import historyEN from "./en/history.json"
import missionEN from "./en/mission.json"
import ceoEN from "./en/ceo.json"
import partnerEN from "./en/partner.json"
import contactEN from "./en/contact.json"
import onpremiseEN from "./en/onpremise.json"
import cloudEN from "./en/cloud.json"
import vmdrEN from "./en/vmdr.json"
import vmendoEN from "./en/vmendo.json"
import vmfundusEN from "./en/vmfundus.json"
import aiwomanEN from "./en/aiwoman.json"
import aiechoEN from "./en/aiecho.json"
import trouterEN from "./en/trouter.json"
import tgearEN from "./en/tgear.json"
import tclientEN from "./en/tclient.json"
import autovoiceEN from "./en/autovoice.json"
import facialEN from "./en/facial.json"
import bodyEN from "./en/body.json"
import deindentiEN from "./en/deindenti.json"
import geneEN from "./en/gene.json"
import blockchainEN from "./en/blockchain.json"
import remoteEN from "./en/remote.json"
import privacyEN from "./en/privacy.json"
import emailEN from "./en/email.json"
import boardlistEN from "./en/boardlist.json"
import photolistEN from "./en/photolist.json"


const resources = {
    ko: {
        main: mainKO,
        menu: menuKO,
        sublayout: sublayoutKO,
        introduce:introduceKO,
        history:historyKO,
        mission:missionKO,
        ceo:ceoKO,
        partner:partnerKO,
        contact:contactKO,
        onpremise:onpremiseKO,
        cloud:cloudKO,
        vmdr:vmdrKO,
        vmendo:vmendoKO,
        vmfundus:vmfundusKO,
        aiwoman:aiwomanKO,
        aiecho:aiechoKO,
        trouter:trouterKO,
        tgear:tgearKO,
        tclient:tclientKO,
        autovoice:autovoiceKO,
        facial:facialKO,
        body:bodyKO,
        deindenti:deindentiKO,
        gene:geneKO,
        blockchain:blockchainKO,
        remote:remoteKO,
        privacy:privacyKO,
        email:emailKO,
        boardlist:boardlistKO,
        photolist:photolistKO,

    },
    en: {
        main: mainEN,
        menu: menuEN,
        sublayout: sublayoutEN,
        introduce:introduceEN,
        history:historyEN,
        mission:missionEN,
        ceo:ceoEN,
        partner:partnerEN,
        contact:contactEN,
        onpremise:onpremiseEN,
        cloud:cloudEN,
        vmdr:vmdrEN,
        vmendo:vmendoEN,
        vmfundus:vmfundusEN,
        aiwoman:aiwomanEN,
        aiecho:aiechoEN,
        trouter:trouterEN,
        tgear:tgearEN,
        tclient:tclientEN,
        autovoice:autovoiceEN,
        facial:facialEN,
        body:bodyEN,
        deindenti:deindentiEN,
        gene:geneEN,
        blockchain:blockchainEN,
        remote:remoteEN,
        privacy:privacyEN,
        email:emailEN,
        boardlist:boardlistEN,
        photolist:photolistEN,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en', // 기본언어
    fallbackLng: 'ko', // 기본언어에서 찾을 수없는경우
    interpolation: {
        escapeValue: false,
    },
}).then(() => {

});

export default i18n;
