import {takeLatest, put} from "redux-saga/effects";
import {userSet, userLogout} from "./actions";
import {setUser, logoutUser} from "./slice";
import {PayloadAction} from "@reduxjs/toolkit";
import {push} from "redux-first-history";
import {LoginDataInterface} from "./type";
import {encryptStorage} from "../../lib/encryptStorage";

function* userSetSaga(action: PayloadAction<LoginDataInterface>) {
    try {
        //sessionStorage.setItem('config', JSON.stringify(action.payload))
        // console.log(action.payload, '!!!!!!!!!!!!!!')
        yield put(setUser(action.payload))
    } catch (error) {
        // console.log(action.payload)

    }
}

function* userLogoutSaga() {
    try {
        yield put(logoutUser())
        encryptStorage.clear();
    } catch (error) {
        // console.log(error)
    } finally {
        yield put(push('/'))
    }
}

export function* authSaga() {
    yield takeLatest(userSet, userSetSaga);
    yield takeLatest(userLogout, userLogoutSaga);
}