import React from "react";
import "../../assets/css/loading.css"

export default function Loading() {
    return (
        <div id="loading_wrap">
            <div className="loading_bg"></div>
            <div className="ring absolute">
                <span/>
            </div>
            <div className="loading_text">
                Loading....
            </div>
        </div>
    )
}