import React, {useEffect, useRef, useState} from "react";
import Foot_modal from "./foot_modal";
import {Trans,useTranslation} from "react-i18next";

function Email(closeModal:any) {
    const {t} = useTranslation('email');

    return (
        <Foot_modal closeModal={closeModal}>
            <h2>{t('email.h2title')}</h2>
            <div className="emailno_box">
                <Trans components={{p: <p/>,br: <br/>,strong: <strong/>}}>{t('email.p')}</Trans>
            </div>

            <h3 className="h3_butt">{t('email.h3title')}</h3>
            <ul className="ul_type2">
                <li>{t('email.ul.0')}</li>
                <li>{t('email.ul.1')}</li>
                <li>{t('email.ul.2')}</li>
            </ul>
        </Foot_modal>
    )
}

export default Email;