import React from "react";

export default function Error(props:any){
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 64,
                position: 'absolute',
                width: '100%',
                height: '100%',
            }}
        >
            404
        </div>
    )
}