import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "react-query";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor, history} from "./store";
import {Error} from "./pages/error"
import { HistoryRouter } from 'redux-first-history/rr6';
import { ErrorBoundary } from 'react-error-boundary';
import ScrollToContent from './pages/ScrollToContent';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: false
    }
  }
});

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <ErrorBoundary FallbackComponent={Error}>
                        <HistoryRouter history={history}>
                          {/* <ScrollToContent> */}
                            <App />
                          {/* </ScrollToContent> */}
                        </HistoryRouter>
                    </ErrorBoundary>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
// reportWebVitals(console.log);
