import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as IconClose} from '../assets/img/icon_close.svg'

function FootModal({closeModal, children}) {
    return (
        <div className="foot_modal_background">
            <div className="foot_modal">
                <div className="modal_tit">
                    <button onClick={closeModal} className="close"><i><IconClose/></i></button>
                </div>
                <div className="modal_cont">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default FootModal;