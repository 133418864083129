import React from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import menuData from "./menuData";
import { useDispatch } from "react-redux";
import {menuSet as menuSetSagaStart} from '../store/config';
import { getActiveMenu } from "./Layout";
import {useTranslation} from "react-i18next";
function Gnb_1depth() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSelectMenu = (selectItem:any) =>{
        const {click_id, id, path} = selectItem;
        const search_id = click_id ? click_id : id;

        const activeMenu = getActiveMenu(search_id, null)
        dispatch(menuSetSagaStart(activeMenu))
        navigate(path);
    }
    const {t} = useTranslation('menu')
    return (
        <div className="submenu_box gnb">
            <ul>
                {menuData.map((item, index) =>{
                    let title = item?.title || ''
                    if(item['title2']){
                        title = t(item['title2'])
                    }
                    return (
                        <li key={item.click_id ? item.click_id : item.id ? item.id : index} onClick={()=>onSelectMenu(item)}>
                            {title}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Gnb_1depth;