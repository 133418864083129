import { persistStore, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session';
import {combineReducers, configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import {authReducer, authSaga} from "./auth";
import { configReducer, configSaga } from "./config";
import {createReduxHistoryContext} from "redux-first-history";
import { createBrowserHistory } from "history";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  showHistoryAction: true,
})
const whitelist = ['auth']
const sagaMiddleware = createSagaMiddleware();
const rootSaga = function* (){
  yield all([ configSaga(), authSaga()])
}
const persistConfig = {
  key: 'root', // localStorage key
  storage: sessionStorage, // localStorage
  // whitelist: [], // target (reducer name)
  whitelist: whitelist, // target (reducer name)
};
const rootReducer= combineReducers({
  config: configReducer,
  router: routerReducer,
  auth: authReducer
})

const persist_reducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persist_reducer,
  middleware: [sagaMiddleware, routerMiddleware],
  devTools: process.env.NODE_ENV !== 'production'
});
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store)
const history = createReduxHistory(store);

export {store, persistor, history}