import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {configInterface, configJsonInterface} from "./type";
import {prefix} from "./actions";

// 초기 state
const initialState: configInterface = {
    config: {baseUrl: ''},
    menu: {}
};

// slice
const config = createSlice({
    name: prefix,
    initialState,
    reducers: {
        setConfig: (state, action: PayloadAction<configJsonInterface>) => {
            state.config = action.payload;
        },
        setMenu: (state, action) => {
            state.menu = action.payload
        }
    }
})

export const {setConfig, setMenu} = config.actions;
export const configReducer = config.reducer;