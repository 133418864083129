import React from 'react'

export default function NonePage() {
  return (
    <div>
        <div>
            <strong>권한이 없거나 존재하지 않는 페이지입니다.</strong>
            <button>이전화면ㅋ</button>

        </div>
        <div>Inviz</div>
    </div>
  )
}
